import * as React from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

function Services({ services }) {
  return (
    <article className="bg-navy">
      <div
        className={`grid w-full gap-12 px-4 py-16 mx-auto max-w-7xl md:py-36 sm:px-6 lg:px-8 ${
          services.length === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
        }`}
      >
        {services.map((service) => (
          <div key={nanoid()} className="flex flex-col">
            <service.icon className="w-20 h-20 mx-auto text-gold sm:w-24 sm:h-24" />
            <h3 className="mt-4 text-center heading-3 text-gold">
              {service.title}
            </h3>
            <p className="mx-auto mt-6 font-light leading-6 tracking-widest text-center text-white max-w-prose">
              {service.description}
            </p>
            <div className="flex justify-center pt-8 mt-auto">
              <Link to={service.url} className="button button-inverted">
                Learn more
              </Link>
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

Services.propTypes = {
  services: PropTypes.array.isRequired,
};

export { Services };
