import * as React from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { useGraphQL } from '../hooks';
import { mainNavigation } from '../data';
import { Logo } from './vectors';

function Footer() {
  const {
    site: {
      siteMetadata: { address, title, email, phone, phoneFormatted, hours },
    },
  } = useGraphQL();

  return (
    <footer className="py-12 bg-navy">
      <div className="grid w-full gap-8 px-4 mx-auto max-w-7xl md:grid-cols-3 sm:px-6 lg:px-8">
        <div>
          <Link
            to="/"
            className="flex items-start justify-center flex-shrink-0 transition duration-150 ease-in-out md:justify-start hover:opacity-75 focus:opacity-75"
          >
            <span className="sr-only">{title} logo</span>
            <Logo
              aria-hidden
              focusable={false}
              className="fill-current w-36 md:w-56"
            />
          </Link>
        </div>

        <nav>
          <ul>
            {mainNavigation.map((node) => (
              <li key={nanoid()}>
                <div className="px-5 py-2 text-center md:text-left">
                  <Link
                    to={node.slug}
                    className="text-base font-medium leading-6 text-white"
                  >
                    {node.label}
                  </Link>
                  {node.subMenu && <SubMenu node={node} />}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <ul className="space-y-2 text-center md:text-left">
          <li>
            <a href="tel:0429652211" className="font-medium text-white">
              James Templeton: (04) 2965 2211
            </a>
          </li>
          <li>
            <a href="tel:0401344237" className="font-medium text-white">
              Dennis Warren: (04) 0134 4237
            </a>
          </li>
          <li>
            <a href={`mailto:${email}`} className="font-medium text-white">
              {email}
            </a>
          </li>
          <li>
            <span className="font-medium text-white">
              {address.line1}, {address.line2}
            </span>
          </li>
          <li>
            <span className="font-medium text-white">{hours.office}</span>
          </li>
        </ul>
      </div>

      <p className="mt-12 text-center text-white">
        <a
          href="https://www.pd.design"
          target="_blank"
          rel="noreferrer"
          className="inline-block"
        >
          Designed and developed by PD
        </a>
      </p>
    </footer>
  );
}

function SubMenu({ node }) {
  const submenu = node.subMenu
    .filter((sub) => sub.label !== 'All Services')
    .filter((sub) => sub.label !== 'All Products');
  return (
    <ul>
      {submenu.map((subMenuItem) => (
        <li key={nanoid()}>
          <Link
            to={subMenuItem.slug}
            className="mt-1 text-base font-light leading-6 text-white"
          >
            <span aria-hidden>- </span>
            {subMenuItem.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}

SubMenu.propTypes = {
  node: PropTypes.object.isRequired,
};

export { Footer };
