import * as React from 'react';

function CheckmarkIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 70 77.9" {...props}>
      <path d="M68.5 13.8C57.3 10.9 46 6.3 35.8.7c-.5-.3-1-.3-1.5 0C23.9 6.5 13.2 10.8 1.6 13.8c-.7.2-1.1.8-1.1 1.5v16.6C.5 49 8.8 60.3 15.8 66.8c7.5 6.9 16.2 10.8 19.3 10.8 3.1 0 11.8-3.8 19.3-10.8 7-6.5 15.3-17.8 15.3-34.9V15.3c0-.7-.5-1.3-1.2-1.5zM52.3 64.5c-4.1 3.8-8.2 6.3-10.8 7.6-3.4 1.7-5.7 2.3-6.4 2.3-.7 0-3.1-.6-6.4-2.3-2.6-1.4-6.7-3.8-10.8-7.6-6.6-6.1-14.4-16.6-14.4-32.6V16.4c11.1-3 21.5-7.2 31.6-12.7 9.9 5.4 20.7 9.8 31.6 12.7v15.5c0 16-7.8 26.5-14.4 32.6z" />
      <path d="M63.3 17.8C53.8 15 44.5 11.3 35.8 6.6c-.4-.2-1-.2-1.4 0-8.8 4.7-18 8.4-27.5 11.2-.6.2-1.1.8-1.1 1.4v12.6c0 5.9 1.1 11.5 3.3 16.6 2.2 5.1 5.4 9.8 9.7 13.8 3 2.9 6.5 5.5 10.1 7.4.6.3 4 2.1 5.9 2.5h.6c2-.4 5.3-2.1 5.9-2.5 3.6-1.9 7.1-4.5 10.1-7.4 4.2-4.1 7.5-8.7 9.7-13.8 2.2-5.1 3.3-10.7 3.3-16.6V19.3c0-.7-.4-1.3-1.1-1.5zm-1.9 14.1c0 11-4.1 20.5-12.1 28.2-2.8 2.7-6.1 5.1-9.5 7-1.5.8-3.6 1.8-4.8 2.1-1.2-.3-3.2-1.3-4.8-2.1-3.4-1.8-6.6-4.2-9.5-7-8-7.7-12.1-17.2-12.1-28.2V20.4c9.1-2.7 17.9-6.3 26.3-10.7 8.4 4.4 17.2 8 26.3 10.7v11.5z" />
      <path d="M28.6 32.9c-.9-.9-2.1-1.4-3.3-1.4-1.3 0-2.4.5-3.3 1.4-1.8 1.8-1.8 4.8 0 6.7l6.8 6.8c.9.9 2.1 1.4 3.3 1.4 1.3 0 2.4-.5 3.3-1.4l12.9-12.9c.9-.9 1.4-2.1 1.4-3.3 0-1.3-.5-2.4-1.4-3.3-.9-.9-2.1-1.4-3.3-1.4-1.3 0-2.4.5-3.3 1.4L32 36.4l-3.4-3.5zm4.5 6.7L43.7 29c.3-.3.7-.5 1.2-.5s.9.2 1.2.5c.3.3.5.7.5 1.2s-.2.9-.5 1.2L33.2 44.3c-.3.3-.7.5-1.2.5s-.9-.2-1.2-.5L24 37.5c-.3-.3-.5-.7-.5-1.2s.2-.9.5-1.2c.3-.3.7-.5 1.2-.5s.9.2 1.2.5l4.5 4.5c.7.6 1.6.6 2.2 0z" />
    </svg>
  );
}

export { CheckmarkIcon };
