import * as React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const transition = { min: 0, max: 100, bounceDamping: 9 };

function LightBox({ showDialog, setShowDialog, imageData }) {
  const close = () => setShowDialog(false);

  const MotionDialogOverlay = motion.custom(DialogOverlay);

  return (
    <>
      <AnimatePresence>
        {showDialog && (
          <MotionDialogOverlay
            onDismiss={close}
            initial="closed"
            animate="open"
            exit="closed"
            variants={{ open: { opacity: 1 }, closed: { opacity: 0 } }}
            transition={transition}
            className="fixed inset-0 z-40 flex overflow-y-auto light-box"
          >
            <DialogContent
              className="flex justify-center"
              aria-label="Gallery light box"
            >
              <div
                className="relative flex justify-center w-full my-auto"
                style={{ maxWidth: '43rem' }}
              >
                <div className="absolute top-0 left-0 p-1 -mt-8 -ml-10">
                  <button
                    type="button"
                    onClick={() => setShowDialog(false)}
                    aria-label="Close sidebar"
                    className="flex items-center justify-center w-8 h-8 bg-white bg-opacity-0 rounded-full hover:bg-opacity-25 focus:bg-opacity-25"
                  >
                    <svg
                      className="w-6 h-6 text-white"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <figure className="w-full">
                  {imageData.image && (
                    <Image
                      style={{ maxHeight: '80vh', maxWidth: '43rem' }}
                      fluid={imageData.image}
                    />
                  )}

                  {imageData.altText && (
                    <figcaption className="mt-4 font-sans text-xl tracking-wide text-center text-white uppercase">
                      {imageData.altText}
                    </figcaption>
                  )}
                </figure>
              </div>
            </DialogContent>
          </MotionDialogOverlay>
        )}
      </AnimatePresence>
    </>
  );
}

LightBox.propTypes = {
  showDialog: PropTypes.bool,
  setShowDialog: PropTypes.func,
  imageData: PropTypes.object,
};

export { LightBox };
