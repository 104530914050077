import * as React from 'react';
import {
  GoogleMap as Map,
  useLoadScript,
  Marker,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';

import { styles } from './styles';

const libraries = ['places'];

const options = {
  styles,
  disableDefaultUI: true,
  zoomControl: true,
};

function GoogleMap({
  center = {
    lat: -31.450875,
    lng: 152.885201,
  },
  zoom = 16,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const mapContainerStyle = {
    height: '100%',
    width: '100%',
  };

  if (loadError) return 'Error';
  if (!isLoaded) return 'Loading...';

  return (
    <Map
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      center={center}
      options={options}
    >
      <Marker position={center} />
    </Map>
  );
}

GoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number.isRequired,
};

export { GoogleMap };
