import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { NetlifyForm, Input, TextArea } from './form-elements';
import { ContactInfo } from './contact-info';
import suits from '../images/suits.svg';

function Contact() {
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const backgroundSize = 96;

  return (
    <article id="contact-form" className="relative bg-navy">
      <div
        style={{ backgroundImage: `url(${suits})`, backgroundSize }}
        className="absolute inset-0 opacity-50"
      >
        <div
          style={{
            backgroundImage: `url(${suits})`,
            backgroundSize,
            backgroundPosition: `${backgroundSize / 2}px ${
              backgroundSize / 2
            }px`,
          }}
          className="absolute inset-0"
        />
      </div>
      <div className="relative w-full px-4 py-32 sm:px-6 lg:px-8">
        <div className="flex flex-wrap w-full mx-auto max-w-7xl">
          <div className="w-full lg:px-12 lg:w-3/5">
            <h2 className="text-white heading-2">Contact Us</h2>
            <div className="mt-12">
              <NetlifyForm
                handleSubmit={handleSubmit}
                setIsSubmitting={setIsSubmitting}
                className="grid w-full max-w-lg grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <Input
                  name="company_name"
                  label="Company Name"
                  isFullWidth
                  placeholder="Company Name"
                  onDark
                  register={register}
                  errors={errors}
                />
                <Input
                  name="phone_number"
                  label="Contact Number"
                  type="tel"
                  isFullWidth
                  placeholder="Contact Number"
                  onDark
                  register={register}
                  errors={errors}
                />
                <Input
                  name="email"
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  isFullWidth
                  onDark
                  register={register}
                  errors={errors}
                />
                <TextArea
                  name="message"
                  label="Message"
                  placeholder="Message"
                  rows={7}
                  onDark
                  register={register}
                  errors={errors}
                />
                <div className="sm:col-span-2">
                  <span className="inline-flex w-full shadow-sm">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`button ${
                        isSubmitting ? 'opacity-50 cursor-wait' : ''
                      }`}
                    >
                      Submit
                    </button>
                  </span>
                </div>
              </NetlifyForm>
            </div>
          </div>
          <div className="w-full pt-12 my-auto space-y-2 lg:pt-0 lg:px-12 lg:space-y-8 lg:w-2/5">
            <ContactInfo />
          </div>
        </div>
      </div>
    </article>
  );
}
export { Contact };
