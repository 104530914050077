import * as React from 'react';
import { AustraliaIcon, CheckmarkIcon, ClockIcon } from './vectors';

const reasons = [
  {
    icon: ClockIcon,
    text: '10 Years Experience',
  },
  {
    icon: AustraliaIcon,
    text: 'Australian Made',
  },
  {
    icon: CheckmarkIcon,
    text: '5 Years Warranty',
  },
];

function WhyWorkWithUs() {
  return (
    <article>
      <div className="w-full px-4 py-24 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <h2 className="text-center heading-2 text-gold">Why work with us?</h2>
        <div className="flex flex-wrap px-6 mt-16 space-y-8 text-center md:space-y-0">
          {reasons.map((reason) => (
            <div key={reason.text} className="w-full md:w-1/3">
              <reason.icon className="w-24 h-24 mx-auto" />
              <p className="mt-4 text-xl font-bold">{reason.text}</p>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

export { WhyWorkWithUs };
