export const mainNavigation = [
  {
    label: 'About',
    slug: '/about/',
  },
  {
    label: 'Services',
    slug: '/services/',
    subMenu: [
      {
        label: 'All Services',
        slug: '/services/',
      },
      {
        label: 'Gaming Base Design',
        slug: '/services/gaming-base-design/',
      },
      {
        label: 'Project Management',
        slug: '/services/project-management/',
      },
      {
        label: 'Gaming Room Design',
        slug: '/services/gaming-room-design/',
      },
    ],
  },
  {
    label: 'Projects',
    slug: '/projects/',
  },
  {
    label: 'Contact',
    slug: '/contact/',
  },
];

export const footerNavigation = [{ label: 'Home', slug: '/' }];
