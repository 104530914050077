import * as React from 'react';

function ClockIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 79.6 79.3" {...props}>
      <path d="M67.5 12.1C60.1 4.7 50.3.6 39.8.6S19.5 4.7 12.1 12.1.6 29.3.6 39.8s4.1 20.3 11.5 27.7S29.3 79 39.8 79s20.3-4.1 27.7-11.5S79 50.2 79 39.8s-4.1-20.3-11.5-27.7zm-1.7 53.6c-6.9 6.9-16.2 10.8-26 10.8s-19-3.8-26-10.8C6.9 58.8 3 49.5 3 39.7s3.8-19 10.8-26S30 3 39.8 3s19 3.8 26 10.8 10.8 16.2 10.8 26-3.9 19-10.8 25.9z" />
      <path d="M8 39.8C8 22.2 22.3 7.9 39.8 7.9c6.3 0 12.4 1.9 17.7 5.4.6.4 1.3.2 1.7-.3.4-.6.2-1.3-.3-1.7-5.6-3.8-12.2-5.8-19-5.8-9.2 0-17.8 3.6-24.2 10s-10 15.1-10 24.2c0 8.4 3.1 16.6 8.7 22.8.2.3.6.4.9.4.3 0 .6-.1.8-.3.5-.5.5-1.2.1-1.7C10.9 55.1 8 47.6 8 39.8zm60.3-19.1c-.4-.6-1.1-.7-1.7-.3-.6.4-.7 1.1-.3 1.7 3.5 5.2 5.4 11.4 5.4 17.7 0 17.5-14.3 31.8-31.8 31.8-7.3 0-14.4-2.5-20-7.1-.5-.4-1.3-.3-1.7.2s-.3 1.3.2 1.7C24.3 71.3 32 74 39.8 74 49 74 57.6 70.4 64 64c6.5-6.5 10-15.1 10-24.2.1-6.9-1.9-13.4-5.7-19.1zM61 16c.8.7 1.6 1.5 2.4 2.3.2.3.6.4.9.4.3 0 .6-.1.8-.3.5-.5.5-1.2.1-1.7-.8-.9-1.7-1.7-2.5-2.5-.5-.5-1.3-.4-1.7.1-.5.5-.5 1.3 0 1.7z" />
      <path d="M39.8 16.5c.7 0 1.2-.5 1.2-1.2v-3.7c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v3.7c0 .6.5 1.2 1.2 1.2zm-1.2 47.7v3.7c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-3.7c0-.7-.5-1.2-1.2-1.2s-1.2.6-1.2 1.2zm-22-24.4c0-.7-.5-1.2-1.2-1.2h-3.7c-.7 0-1.2.5-1.2 1.2S11 41 11.7 41h3.7c.6 0 1.2-.6 1.2-1.2zm46.5 0c0 .7.5 1.2 1.2 1.2H68c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2h-3.7c-.7-.1-1.2.5-1.2 1.2zM28.6 17.9l-1.8-3.2c-.3-.6-1.1-.8-1.7-.4-.6.3-.8 1.1-.4 1.7l1.8 3.2c.2.4.6.6 1.1.6.2 0 .4-.1.6-.2.6-.3.8-1.1.4-1.7zm24.5 42.4c-.3-.6-1.1-.8-1.7-.4-.6.3-.8 1.1-.4 1.7l1.8 3.2c.2.4.6.6 1.1.6.2 0 .4-.1.6-.2.6-.3.8-1.1.4-1.7l-1.8-3.2zm-37.7-5.2c.2 0 .4-.1.6-.2l3.2-1.8c.6-.3.8-1.1.4-1.7-.3-.6-1.1-.8-1.7-.4l-3.2 1.8c-.6.3-.8 1.1-.4 1.7.3.3.7.6 1.1.6zM61 28.7c.2 0 .4-.1.6-.2l3.2-1.8c.6-.3.8-1.1.4-1.7-.3-.6-1.1-.8-1.7-.4l-3.2 1.8c-.6.3-.8 1.1-.4 1.7.3.4.7.6 1.1.6zm-6.5-14.4c-.6-.3-1.3-.1-1.7.4L51 17.9c-.3.6-.1 1.3.4 1.7.2.1.4.2.6.2.4 0 .8-.2 1.1-.6L55 16c.3-.6.1-1.3-.5-1.7zM28.2 59.9c-.6-.3-1.3-.1-1.7.4l-1.8 3.2c-.3.6-.1 1.3.4 1.7.2.1.4.2.6.2.4 0 .8-.2 1.1-.6l1.8-3.2c.4-.6.2-1.4-.4-1.7zm32.2-6.8l3.2 1.8c.2.1.4.2.6.2.4 0 .8-.2 1.1-.6.3-.6.1-1.3-.4-1.7L61.7 51c-.6-.3-1.3-.1-1.7.4-.4.6-.2 1.3.4 1.7zM19.2 26.5L16 24.6c-.6-.3-1.3-.1-1.7.4-.3.6-.1 1.3.4 1.7l3.2 1.8c.2.1.4.2.6.2.4 0 .8-.2 1.1-.6.4-.6.2-1.3-.4-1.6zm29.1 29.2c.2 0 .4-.1.6-.2.6-.3.8-1.1.4-1.7L42.5 42c.5-.6.9-1.5.9-2.4 0-1.6-1-3-2.4-3.5V20.2c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v16.1c-1.4.5-2.4 1.9-2.4 3.5 0 2 1.6 3.7 3.7 3.7.2 0 .5 0 .7-.1l6.8 11.8c.1.3.5.5.9.5zm-9.7-15.9c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2-.5 1.2-1.2 1.2-1.2-.6-1.2-1.2z" />
    </svg>
  );
}

export { ClockIcon };
