import * as React from 'react';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

function ImageGrid({ images }) {
  return (
    <article className="bg-navy">
      <div className="w-full mx-auto max-w-7xl">
        <div className="flex flex-wrap px-4 pb-16 sm:px-6 lg:px-8">
          {images.map((image) => (
            <div key={nanoid()} className="w-1/2 md:w-1/4">
              <div className="relative overflow-hidden aspect-w-4 aspect-h-4">
                <div className="absolute inset-0 flex">
                  <GatsbyImage
                    fluid={image.childImageSharp.fluid}
                    className="flex-1"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

ImageGrid.propTypes = {
  images: PropTypes.array.isRequired,
};

export { ImageGrid };
