/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';

import { Navbar } from './navbar';
import { Footer } from './footer';
import { HeaderIndicator } from './header-indicator';

function Layout({ children }) {
  return (
    <div className="relative flex flex-col min-h-screen font-sans antialiased text-gray-700 bg-white fill-available">
      <Navbar />
      <main className="flex-1">
        <HeaderIndicator />
        {children}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Layout };
