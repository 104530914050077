import PropTypes from 'prop-types';
import * as React from 'react';

function Error({ message, onDark }) {
  return (
    <p
      role="alert"
      className={`mt-1 text-sm font-medium tracking-widest uppercase ${
        onDark ? 'text-red-200' : 'text-gold'
      }`}
    >
      {message}
    </p>
  );
}

Error.propTypes = {
  message: PropTypes.string,
  onDark: Boolean,
};

export { Error };
