import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { NetlifyForm, Input, TextArea } from './form-elements';

function GetInTouch() {
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <article className="relative overflow-hidden bg-white">
      <div className="px-4 py-32 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="text-center text-gray-900 heading-2">Get in touch</h2>
        <NetlifyForm
          handleSubmit={handleSubmit}
          setIsSubmitting={setIsSubmitting}
          className="grid max-w-xl grid-cols-1 mx-auto mt-8 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        >
          <Input
            name="company_name"
            label="Company Name"
            isFullWidth
            placeholder="Company Name"
            register={register}
            errors={errors}
          />
          <Input
            name="phone_number"
            label="Contact Number"
            type="tel"
            isFullWidth
            placeholder="Contact Number"
            register={register}
            errors={errors}
          />
          <Input
            name="email"
            label="Email Address"
            type="email"
            placeholder="Email Address"
            isFullWidth
            register={register}
            errors={errors}
          />
          <TextArea
            name="message"
            label="Message"
            placeholder="Message"
            rows={7}
            register={register}
            errors={errors}
          />
          <div className="flex justify-center md:col-span-2">
            <button
              type="submit"
              disabled={isSubmitting}
              className={`button ${
                isSubmitting ? 'opacity-50 cursor-wait' : ''
              }`}
            >
              Submit
            </button>
          </div>
        </NetlifyForm>
      </div>
    </article>
  );
}

export { GetInTouch };
