import * as React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

function TheServices({ items }) {
  return (
    <article className="pt-24 bg-navy">
      <div className="w-full px-4 py-16 mx-auto space-y-24 max-w-7xl md:py-36 sm:px-6 lg:px-8">
        {items.map((item, index) => (
          <div
            className={`flex flex-col items-center md:px-12 ${
              index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
            }`}
            key={nanoid()}
          >
            <div className="w-full lg:w-1/2">
              <div className="max-w-lg mx-auto">
                <div className="relative h-0 aspect-w-4 aspect-h-4">
                  <div className="absolute inset-0 flex">
                    <Image fluid={item.image} className="flex-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center mt-14 lg:mt-0 lg:w-1/2 md:px-14">
              <div>
                {item.icon}
                <h2 className="mt-4 text-center heading-3 text-gold">
                  {item.title}
                </h2>
                <div className="mt-6 prose text-center text-white">
                  <p className="clamp-4">{item.description}</p>
                </div>
                <div className="flex justify-center mt-8">
                  <Link to={item.url} className="button button-inverted">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </article>
  );
}

TheServices.propTypes = {
  items: PropTypes.array.isRequired,
};

export { TheServices };
