import * as React from 'react';

import { GoogleMap } from './google-map';

function Map() {
  return (
    <article className="relative h-0 aspect-w-16 aspect-h-9 lg:pb-0 lg:h-96">
      <div className="absolute inset-0">
        <GoogleMap />
      </div>
    </article>
  );
}

export { Map };
