import * as React from 'react';
import Image from 'gatsby-image';
import chunk from 'lodash/chunk';
import { nanoid } from 'nanoid';

import { useGraphQL } from '../../hooks';
import { LightBox } from './light-box';

function GalleryGrid() {
  const {
    allSanityGallery: { edges },
  } = useGraphQL();
  const images = edges[0]?.node?.images;

  const rowSpans = [
    [3, 4, 5],
    [4, 5, 3],
    [3, 5, 4],
  ];
  const groupedImages = chunk(images, 3).slice(0, 3);

  const [showDialog, setShowDialog] = React.useState(false);
  const [activeImage, setActiveImage] = React.useState(null);

  const handleClick = (image, altText) => {
    setShowDialog(true);
    setActiveImage({ image, altText });
  };

  return (
    <article className="pt-10 sm:pt-12 lg:pt-24 bg-navy">
      <div className="w-full px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <LightBox
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          imageData={activeImage}
        />
        <div className="relative aspect-w-4 aspect-h-6 md:aspect-w-10 md:aspect-h-10">
          <div className="absolute inset-0 flex">
            <div className="flex-1">
              <div className="grid h-full grid-flow-col grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 grid-rows-12">
                {groupedImages.map((group, i) => {
                  return group.map((image, e) => (
                    <button
                      key={nanoid()}
                      type="button"
                      className={`relative flex row-span-${rowSpans[i][e]}`}
                      onClick={() =>
                        handleClick(image.asset.fluid, image.altText)
                      }
                    >
                      <figure type="button" className="absolute inset-0 flex">
                        <Image className="flex-1" fluid={image?.asset?.fluid} />
                      </figure>
                    </button>
                  ));
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export { GalleryGrid };
