import PropTypes from 'prop-types';
import * as React from 'react';

export function BoardIcon({ className }) {
  return (
    <svg viewBox="0 0 79.6 85.2" fill="currentColor" className={className}>
      <path d="M76.4 8.6c1.4 0 2.5-1.1 2.5-2.5V3.3c0-1.4-1.1-2.5-2.5-2.5H3.2C1.8.8.7 1.9.7 3.3v2.8c0 1.4 1.1 2.5 2.5 2.5h.2v7.2c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2V8.6h67.9v49H5.8V20.7c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v36.9h-.2c-1.4 0-2.5 1.1-2.5 2.5v2.8c0 1.4 1.1 2.5 2.5 2.5h7.9L6.6 81.1c-.2.8-.1 1.6.4 2.3.5.7 1.2 1 2.1 1h5.3c1.1 0 2.2-.8 2.5-1.9l1-3.5h16.6v2.8c0 1.4 1.2 2.6 2.6 2.6h5.4c1.4 0 2.6-1.2 2.6-2.6V79h16.6l1 3.5c.3 1.1 1.3 1.9 2.5 1.9h5.3c.8 0 1.6-.4 2.1-1 .5-.7.6-1.5.4-2.3l-4.5-15.7h7.9c1.4 0 2.5-1.1 2.5-2.5v-2.8c0-1.4-1.1-2.5-2.5-2.5h-.2v-49h.2zM14.4 82H9.1s-.1 0-.1-.1v-.1l4.7-16.3h5.6l-4.7 16.4c-.1 0-.1.1-.2.1zm28.3-.2c0 .1-.1.1-.1.1h-5.4c-.2.1-.2 0-.2-.1V79h5.7v2.8zm-24.1-5.2l.8-3h40.7l.8 3H18.6zm24.1-11.1v5.6H37v-5.6h5.7zm27.9 16.4c0 .1-.1.1-.1.1h-5.3c-.1 0-.1 0-.1-.1l-4.7-16.4H66l4.7 16.3c-.1 0 0 .1-.1.1zM59.5 71.1H45.1v-5.7h12.7l1.7 5.7zm17-11v2.8s0 .1-.1.1H29.1c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h5.5v5.7H20.2l1.6-5.7h2.4c.7 0 1.2-.5 1.2-1.2s-.5-1.2-1.2-1.2h-21s-.1 0-.1-.1v-2.8s0-.1.1-.1l73.3.1zM3.2 6.2s-.1-.1 0 0l-.1-2.9s0-.1.1-.1h73.2s.1 0 .1.1v2.8s0 .1-.1.1H3.2z" />
      <path d="M16.9 50.7c0 2.2 1.8 3.9 3.9 3.9s3.9-1.8 3.9-3.9c0-2.2-1.8-3.9-3.9-3.9s-3.9 1.7-3.9 3.9zm4-1.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.6-1.5 1.5-1.5zm39.9-21.7c2.2 0 3.9-1.8 3.9-3.9 0-2.2-1.8-3.9-3.9-3.9s-3.9 1.8-3.9 3.9 1.7 3.9 3.9 3.9zm0-5.4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zM19.6 42.6c0 .7.5 1.2 1.2 1.2s1.2-.5 1.2-1.2v-2.7c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v2.7zm1.3-6.9c.7 0 1.2-.5 1.2-1.2v-2.7c0-.7-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2v2.7c-.1.6.5 1.2 1.2 1.2zm-3.6-8.5c.2.2.6.4.9.4s.6-.1.9-.4l1.8-1.8 1.8 1.8c.2.2.6.4.9.4.3 0 .6-.1.9-.4.5-.5.5-1.3 0-1.7l-1.8-1.8 1.8-1.8c.5-.5.5-1.3 0-1.7-.5-.5-1.3-.5-1.7 0L21 22l-2-1.9c-.5-.5-1.3-.5-1.7 0-.5.5-.5 1.3 0 1.7l1.8 1.8-1.8 1.8c-.5.6-.5 1.3 0 1.8zm11 11.3c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h32.5c.7 0 1.2-.5 1.2-1.2v-5.2l.6.6c.2.2.6.4.9.4s.6-.1.9-.4c.5-.5.5-1.3 0-1.7l-2.7-2.7c-.5-.5-1.3-.5-1.7 0l-2.7 2.7c-.5.5-.5 1.3 0 1.7.5.5 1.3.5 1.7 0l.6-.6v4H28.3z" />
    </svg>
  );
}

BoardIcon.propTypes = {
  className: PropTypes.string,
};
