import * as React from 'react';
import Image from 'gatsby-image';
import { nanoid } from 'nanoid';

import { useGraphQL } from '../hooks/use-graphql';

function OurClients() {
  const { clientLogos } = useGraphQL();

  return (
    <article>
      <div className="px-4 mx-auto max-w-7xl py-36 sm:px-6 lg:px-8">
        <h2 className="text-center heading-2 text-gold">Our Clients</h2>
        <div className="flex flex-wrap items-center mt-16">
          {clientLogos.nodes.map((logo) => (
            <div className="w-1/2 p-8 md:w-1/5" key={nanoid()}>
              <Image
                fluid={logo.childImageSharp.fluid}
                className="flex-1 mx-auto"
              />
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

export { OurClients };
