import * as React from 'react';
import suits from '../images/suits.svg';

const backgroundSize = 96;

function SuitsOverlay() {
  return (
    <div aria-hidden className="relative">
      <div
        style={{ backgroundImage: `url(${suits})`, backgroundSize }}
        className="absolute inset-0 z-10 h-24 transform -translate-y-1/2 lg:h-48"
      >
        <div
          style={{
            backgroundImage: `url(${suits})`,
            backgroundSize,
            backgroundPosition: `${backgroundSize / 2}px ${
              backgroundSize / 2
            }px`,
          }}
          className="absolute inset-0"
        />
      </div>
    </div>
  );
}
export { SuitsOverlay };
