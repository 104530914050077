import * as React from 'react';

import { useGraphQL } from '../hooks';

function ContactInfo() {
  const {
    site: {
      siteMetadata: { address, email, hours, phone, phoneFormatted },
    },
  } = useGraphQL();
  return (
    <dl className="mt-8 space-y-4">
      <div>
        <dt className="inline text-lg font-bold text-gold">Phone: </dt>
        <dd className="inline font-medium text-white">
          <a href="tel:0429652211" className="inline">
            James Templeton - (04) 2965 2211
          </a>
        </dd>
      </div>
      <div>
        <dt className="inline text-lg font-bold text-gold">Phone: </dt>
        <dd className="inline font-medium text-white">
          <a href="tel:0401344237" className="inline">
            Dennis Warren - (04) 0134 4237
          </a>
        </dd>
      </div>
      <div>
        <dt className="inline text-lg font-bold text-gold">Address: </dt>
        <dd className="inline font-medium text-white">
          {address.line1}, {address.line2}
        </dd>
      </div>
      <div>
        <dt className="inline text-lg font-bold text-gold">Email: </dt>
        <dd className="inline font-medium text-white">
          <a href={`mailto:${email}`}>{email}</a>
        </dd>
      </div>
      <div>
        <dt className="inline text-lg font-bold text-gold">Office Hours: </dt>
        <dd className="inline font-medium text-white">{hours.office}</dd>
      </div>
      <div>
        <dt className="inline text-lg font-bold text-gold">Business Hours: </dt>
        <dd className="inline font-medium text-white">{hours.business}</dd>
      </div>
    </dl>
  );
}

export { ContactInfo };
