import * as React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function NetlifyForm({
  action = '/success/',
  children,
  className,
  handleSubmit,
  name = 'contact_form',
  setIsSubmitting,
  ...props
}) {
  const onSubmit = handleSubmit((data, event) => {
    if (event) {
      event.preventDefault();
      setIsSubmitting(true);
      const form = event.target;
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...data,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error));
    }
  });
  return (
    <form
      data-netlify
      netlify-honeypot="bot-field"
      action={action}
      method="POST"
      name={name}
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      {...props}
    >
      <div className="hidden">
        <label htmlFor="bot-field">
          Don't fill this out if you're human:
          <input id="bot-field" name="bot-field" />
        </label>
      </div>
      {children}
    </form>
  );
}

NetlifyForm.propTypes = {
  action: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string,
  setIsSubmitting: PropTypes.func.isRequired,
};

export { NetlifyForm };
