import * as React from 'react';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';

function ImageWithText({ image, heading, copy, marginTop = false }) {
  return (
    <article className={`bg-navy ${marginTop && 'pt-24'}`}>
      <div className="w-full py-16 mx-auto max-w-7xl">
        <div className="flex flex-col items-center px-4 sm:px-6 lg:px-8 lg:flex-row">
          <div className="w-full sm:w-4/5 md:w-3/5 lg:w-2/5">
            <div className="relative overflow-hidden aspect-w-4 aspect-h-4">
              <div className="absolute inset-0 flex">
                <GatsbyImage fluid={image} className="flex-1" />
              </div>
            </div>
          </div>
          <div className="flex items-center mt-14 lg:mt-0 lg:w-3/5 md:px-14">
            <div>
              <h2 className="text-white heading-3 text-mono">{heading}</h2>
              <div className="mt-4 prose text-white">
                <p>{copy}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

ImageWithText.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  image: PropTypes.object.isRequired,
  marginTop: PropTypes.bool,
};

export { ImageWithText };
