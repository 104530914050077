import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Menu, Transition } from '@headlessui/react';

import { mainNavigation } from '../data';
import { Logo } from './vectors';
import { MobileMenu } from './mobile-menu';
import { useGraphQL, useSiteContext } from '../hooks';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { isHeaderActive } = useSiteContext();
  const {
    site: { siteMetadata },
  } = useGraphQL();

  return (
    <nav
      className={`fixed inset-x-0 top-0 z-20 border-b border-white border-opacity-25 transition ease-in-out duration-300 ${
        isHeaderActive ? 'bg-navy bg-opacity-75 shadow-md backdrop-blur' : ''
      }`}
    >
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-24">
          <Link to="/" className="p-2 -ml-2 rounded-md">
            <span className="sr-only">{siteMetadata.title}</span>
            <Logo
              aria-hidden
              focusable={false}
              className="h-10 fill-current text-gold"
            />
          </Link>

          <ul className="items-center hidden space-x-8 sm:ml-6 sm:flex">
            {mainNavigation.map((navItem) =>
              navItem.subMenu ? (
                <SubMenu key={navItem.label} node={navItem} />
              ) : (
                <li key={navItem.label}>
                  <Link
                    to={navItem.slug}
                    partiallyActive={navItem.slug !== '/'}
                    activeClassName="text-gold"
                    className="inline-flex items-center px-1 py-1 font-mono text-sm font-bold text-white transition duration-150 ease-in-out border-b-2 border-transparent hover:border-gold focus:shadow-none focus:border-gold"
                  >
                    {navItem.label}
                  </Link>
                </li>
              )
            )}
          </ul>
          <div className="flex items-center -mr-2 sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              onClick={() => setIsOpen((prevState) => !prevState)}
              aria-label="Main menu"
              aria-expanded={isOpen}
              className="inline-flex items-center justify-center p-2 text-white transition duration-150 ease-in-out bg-white bg-opacity-0 rounded-md hover:bg-opacity-25 focus:bg-opacity-25"
            >
              <svg
                className="block w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </nav>
  );
}

function SubMenu({ node }) {
  return (
    <li className="relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex items-center px-1 py-1 font-mono text-sm font-bold text-white transition duration-150 ease-in-out border-b-2 border-transparent hover:border-gold focus:shadow-none focus:border-gold">
              <span>{node.label}</span>
              <span className="ml-2 -mr-1">
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    open
                      ? 'rotate-90 duration-100 ease-out'
                      : 'rotate-0 ease-in duration-75'
                  }`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute left-0 w-56 mt-2 origin-top-left bg-white divide-y divide-gray-100 shadow-lg"
              >
                <ul className="py-1">
                  {node.subMenu.map((submenu) => (
                    <Menu.Item key={submenu.label}>
                      {({ active }) => (
                        <li>
                          <Link
                            to={submenu.slug}
                            className={`${
                              active
                                ? 'border-gold text-gray-900 bg-gray-50'
                                : 'border-transparent text-gray-600 bg-white'
                            } flex justify-between w-full px-4 py-2 text-sm leading-6 text-left`}
                          >
                            <span
                              className={`border-b-2 ${
                                active ? 'border-gold' : 'border-transparent'
                              }`}
                            >
                              {submenu.label}
                            </span>
                          </Link>
                        </li>
                      )}
                    </Menu.Item>
                  ))}
                </ul>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </li>
  );
}

SubMenu.propTypes = {
  node: PropTypes.object.isRequired,
};

export { Navbar };
