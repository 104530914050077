import { BoardIcon, CubeIcon, PaperPencilIcon } from '../components/vectors';
import { useGraphQL } from '../hooks/use-graphql';

function useServices() {
  const {
    gamingBaseDesign,
    projectManagement,
    gamingRoomDesign,
    site: { siteMetadata },
  } = useGraphQL();

  const services = [
    {
      title: 'Gaming Base Design',
      preview: `Design your gaming machine base from start to finish with our expert team. Customise your gaming base to fit your décor and gaming regulator specifications. Select materials, colours, add-ons and more to make a gaming base that’s uniquely yours.`,
      description: `Give your gaming venue a polished, glitzy, or even subtle look with gaming bases manufactured and customised right here in Australia by ${siteMetadata.shortName}. We can cater to all console types, giving you a range of finishes that blend into your décor. We use computer-aided design and precision tooling for unique looks and themes. Your venue can customise bases, screens, benchtops, leading-edge profiles, and add on customised lighting including RGB (rainbow) colours and patterns. The choice is yours – and the choices are endless.`,
      icon: CubeIcon,
      image: gamingBaseDesign.childImageSharp.fluid,
      url: '/services/gaming-base-design/',
    },
    {
      title: 'Project Management',
      preview: `We handle the entire project from design, manufacture, and installation. Trust ${siteMetadata.title} to deliver orders large or small to your venue. We manage the quality and timeliness of your order to ensure cost-effectiveness and peace of mind.`,
      description: `We can take the lead in all things Gaming Machine Bases from concept, design, manufacturing, and installation. Whether you are upgrading your venue, adding some new consoles, or starting from new, the expert team at ${siteMetadata.shortName} can guide you through the process and keep you apprised of all developments. Trust ${siteMetadata.shortName} to give your gaming venue the advantage through our years of expertise.`,
      icon: BoardIcon,
      image: projectManagement.childImageSharp.fluid,
      url: '/services/project-management/',
    },
    {
      title: 'Gaming Room Design',
      preview: `We partner with venues to create entire gaming rooms to suit a variety of casinos, hospitality venues, and clubs. We lay out the position of gaming machines to ensure maximum comfort and a style that suits your vision.`,
      description: `From the entry way to consoles to lighting, ${siteMetadata.shortName} can fully customise a gaming room design that adheres to your vision while maintaining regulatory standards. From benches, reception areas, bases, lighting, and themed consoles, ${siteMetadata.shortName} can advise you on novel and patron-friendly approaches that will stand the test of time. We work with your current design to create your desired atmosphere. We lay out the position of your fit-out and manage the entire project to ensure a success.`,
      icon: PaperPencilIcon,
      image: gamingRoomDesign.childImageSharp.fluid,
      url: '/services/gaming-room-design/',
    },
  ];

  return services;
}

export { useServices };
