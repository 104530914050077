import * as React from 'react';

function GoOverYourProject() {
  return (
    <article className="px-4 pb-24 text-center sm:px-6 lg:px-8 bg-navy">
      <h2 className="text-center text-white heading-3">
        Organise a time to go over your project
      </h2>
      <p className="mt-6">
        <a href="#contact-form" className="button">
          Book a meeting
        </a>
      </p>
    </article>
  );
}

export { GoOverYourProject };
