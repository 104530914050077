import * as React from 'react';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function Hero({ image, heading, copy, hasCTA, hasGradient = true }) {
  return (
    <header style={{ minHeight: '70vh' }} className="relative flex">
      <div className="absolute inset-0 flex">
        <GatsbyImage fluid={image} className="flex-1" />
      </div>
      <div
        aria-hidden
        className="absolute inset-0 pointer-events-none hero-gradient"
      >
        {hasGradient && (
          <div className="absolute inset-0 bg-black bg-opacity-50" />
        )}
      </div>
      <div className="relative flex items-center justify-center w-full pt-40 pb-20 mx-auto max-w-7xl text-shadow lg:py-48">
        <div
          className={`px-4 mx-auto text-center ${
            heading === 'South West Rocks Country Club'
              ? 'lg:w-full'
              : 'lg:w-1/2'
          } sm:px-6 lg:px-8`}
        >
          <h1 className="mx-auto font-mono text-4xl font-medium tracking-tight text-white uppercase sm:text-5xl sm:leading-none">
            {heading}
          </h1>
          <p className="max-w-md mx-auto mt-3 text-lg text-white sm:text-xl md:mt-5 md:max-w-3xl">
            {copy}
          </p>
          {hasCTA && (
            <div className="flex justify-center mt-12">
              <div className="w-full max-w-xs text-center rounded-full">
                <Link to="/" className="button">
                  Return home
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

Hero.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hasCTA: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  image: PropTypes.object.isRequired,
  hasGradient: PropTypes.bool,
};

export { Hero };
